import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";

import image from "../../static/img/akpol_logo_black_blue.png";

const TemplateWrapper = ({
  title: pageTitle,
  pageKeywords,
  pageDescription,
  children,
  pathname,
  banner,
}) => {
  const {
    buildTime,
    siteMetadata: {
      title: defaultTitle,
      description,
      siteUrl,
      defaultBanner,
      author,
    },
  } = useSiteMetadata();

  const title = pageTitle || defaultTitle;
  let titleString = `Akpol | ${title}`;
  if (title === "Akpol") {
    titleString = "Akpol";
  }

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    headline: description,
    inLanguage: "sv",
    mainEntityOfPage: siteUrl,
    description: description,
    name: title,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    datePublished: "2019-01-18T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultBanner}`,
    },
  };

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ];

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{titleString}</title>
        <meta name="description" content={pageDescription || description} />
        <meta name="image" content={image} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon.png"
          sizes="16x16"
        />

        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Navbar />
      <div className="mb-4">{children}</div>
      <Footer />
    </div>
  );
};

TemplateWrapper.propTypes = {
  title: PropTypes.string,
  pageKeywords: PropTypes.string,
  pageDescription: PropTypes.string,
};

export default TemplateWrapper;
