import React from "react";
import { Link } from "gatsby";

import logo from "../../static/img/akpol_logo_blue_symbol.png";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white">
        <div className="content has-text-centered">
          <img src={logo} alt="Akpol" style={{ width: "5em", height: "5em" }} />
        </div>
        <div className="content has-text-centered has-background-black has-text-white">
          <div className="container has-background-black has-text-white">
            <div className="columns">
              <div className="column">
                <section className="menu">
                  <ul className="menu-list has-text-white">
                    <li>
                      <Link
                        to="/"
                        className="navbar-item"
                        style={{ color: "white" }}
                      >
                        Hem
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to="/om-oss"
                        style={{ color: "white" }}
                      >
                        Om Oss
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to="/produkter"
                        style={{ color: "white" }}
                      >
                        Produkter
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to="/kontakt"
                        style={{ color: "white" }}
                      >
                        Kontakt
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column">
                <section>
                  <ul className="menu-list" style={{ textAlign: "center" }}>
                    <li>
                      <a
                        style={{
                          color: "white"
                        }}
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
