import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          buildTime
          siteMetadata {
            title
            description
            siteUrl
            defaultBanner
            author
          }
        }
      }
    `
  );
  return site;
};

export default useSiteMetadata;
